<template>
  <div>
    <v-card class="ma-2">
      <v-card-title class="mx-4">Invoice To</v-card-title>
      <v-form ref="form">
          <v-card-text>
            <v-row class="mx-4">
              <v-col cols="4">
                <v-text-field outlined dense hide-details label="Class" :rules="setValidation('requiredValidation')"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field outlined dense hide-details label="Student" :rules="setValidation('requiredValidation')"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-title>Invoice items</v-card-title>
          <v-card-text>
            <v-row class="mx-4">
              <v-col cols="4">
                <v-text-field outlined dense hide-details label="Class" :rules="setValidation('requiredValidation')"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field outlined dense hide-details label="Student" :rules="setValidation('requiredValidation')"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-title>Payment Detail</v-card-title>
          <v-card-text>
            <v-row class="mx-4">
              <v-col cols="4">
                <v-text-field outlined dense hide-details label="Class" :rules="setValidation('requiredValidation')"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field outlined dense hide-details label="Student" :rules="setValidation('requiredValidation')"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
    </v-card>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>