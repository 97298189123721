<template>
  <div>
     <v-tabs v-model="tab" icons-and-text>
      <v-tabs-slider></v-tabs-slider>

      <v-tab class="mx-7" href="#tab-1">
        Home
        <v-icon>mdi-home</v-icon>
      </v-tab>

      <v-tab class="mx-7" href="#tab-2">
        Payments
        <v-icon>mdi-heart</v-icon>
      </v-tab>

      <v-tab class="mx-7" href="#tab-3">
        Expenses
        <v-icon>mdi-account-box</v-icon>
      </v-tab>
    </v-tabs>
    <v-container fluid v-if="tab === 'tab-1'">
      <v-row>
        <v-col v-for="(i, index) in widgets" :key="index" cols="3">
          <v-card ref="card"  style="border-radius: 9%" outlined  height="200">
            <v-card-text class="py-8">
              <div class="layout ma-0 align-center column">
                <v-avatar size="75">
                  <v-icon size="55">{{i.icon}}</v-icon>
                </v-avatar>
                <div class="flex mt-2 text-sm-center">
                  <div class="title font-weight-black">{{i.title}}</div>
                  <span class="caption">{{i.subtitle}}</span>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <data-iterator :headers="payloadHeaders" :payload="payload"> </data-iterator>
        </v-col>
         <v-col cols="6">
          <data-iterator :headers="payloadHeaders" :payload="payload"> </data-iterator>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-if="tab === 'tab-2'">
      <data-iterator :headers="payloadHeaders" :payload="payload"> </data-iterator>
    </v-container>
    <v-container fluid v-if="tab === 'tab-3'">
      <data-iterator :headers="payloadHeaders" :payload="payload"> </data-iterator>
    </v-container>
   </div>
</template>

<script>
export default {
   data () {
      return {
        tab: null,
        widgets: [
          {
            title: '$150,000',
            subtitle: 'Total income',
            icon: 'mdi-chart-line'
          },
           {
            title: '$150,000',
            subtitle: 'Total income',
            icon: 'mdi-chart-line'
          },
           {
            title: '$150,000',
            subtitle: 'Total income',
            icon: 'mdi-chart-line'
          },
           {
            title: '$150,000',
            subtitle: 'Total income',
            icon: 'mdi-chart-line'
          }
        ],
        payload: {
          title: 'Recent Payments',
          // create: false,
          items: [],
          showSelect: false,
          actionsList: []
        },
      }
    },
  components: {
    'data-iterator': () => import('@/components/DataIterator.vue')
  },
  computed: {
    payloadHeaders () {
      return [{
        text: 'Status',
        value: 'name'
      }, {
        text: 'Student',
        value: 'profile'
      }, {
        text: 'Description',
        value: 'email'
      }, {
        text: 'Amount',
        value: 'actions'
      }]
    }
  },
}
</script>

<style>

</style>