<template>
  <div>
    <v-card class="ma-2">
      <template v-if="userDetails && userDetails.role && (userDetails.role.code !== 1 && (userDetails.role.code === 3 ? true : !managerMode))">
        <v-container fluid>
          <v-row>
            <v-col cols="12" class="pa-0">
              <data-iterator :headers="payloadHeaders" :payload="payload" @filter="getAllLeaveRecords">
                <template #extraDetails v-if="(userDetails && userDetails.role.code === 2)">
                  <v-btn-toggle mandatory dense v-model="managerMode" class="mr-10" color="primary"> <!-- @change="changeMode" -->
                    <v-btn class="caption black--text" :value="false"> My Leave </v-btn>
                    <v-btn class="caption black--text" :value="true"> Leave requests </v-btn>
                  </v-btn-toggle>
                </template>
              </data-iterator>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-else>
        <v-container fluid>
          <v-row>
            <v-col cols="12" class="pa-0">
              <v-card>
                <data-iterator :headers="leaveRequestHeaders" :payload="leaveRequestPayload" @filter="getAllLeaveRequests">
                  <template #extraDetails>
                    <template v-if="userDetails && userDetails.role.code === 2">
                      <v-btn-toggle mandatory dense v-model="managerMode" class="mr-10" color="primary">
                        <v-btn class="caption black--text" :value="false"> My Leave </v-btn>
                        <v-btn class="caption black--text" :value="true"> Leave requests </v-btn>
                      </v-btn-toggle>
                    </template>
                    <template v-if="userDetails && userDetails.role.code !== 3">
                      <v-btn-toggle mandatory dense v-model="viewMode">
                        <v-btn class="caption black--text" :value="2" @click="getAllLeaveRequests({ role: 2 })"> Staff </v-btn>
                        <v-btn class="caption black--text" :value="3" @click="getAllLeaveRequests({ role: 3 })"> Student </v-btn>
                      </v-btn-toggle>
                    </template>
                  </template>
                  <template #staff="{ item }">
                    {{ item.author.formdata.name }}
                  </template>
                  <template #student="{ item }">
                    {{ item.author.formdata.name }}
                    <!-- {{ studentItems.find((studentItem) => studentItem._id === item.author)
                      ? (studentItems.find((studentItem) => studentItem._id === item.author).formdata.name || '')
                      : ''
                    }} -->
                  </template>
                </data-iterator>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-card>
    <v-dialog v-model="applyLeaveDialog" persistent width="420">
      <v-card>
        <v-card-title class="pa-3 pb-2 primary darken-2 white--text">
          <span class="body-1 font-weight-bold"> Apply leave </span>
          <v-spacer></v-spacer>
          <!-- <v-btn icon dark small>
            <v-icon size="20"> mdi-close-circle </v-icon>
          </v-btn> -->
        </v-card-title>
        <v-card-text class="pa-3">
          <v-form ref="leaveForm">
            <v-layout row wrap align-center class="ma-0">
              <v-flex lg12 class="pb-2" v-if="(userDetails && userDetails.role && userDetails.role.code === 2)">
                <v-select outlined dense hide-details :items="leaveTypes" v-model="leaveObj.leavetype"
                label="Leave type" item-text="name" item-value="_id" :rules="setValidation('requiredValidation')"></v-select>
              </v-flex>
              <v-flex lg6 class="pr-1">
                <v-menu v-model="leaveObj.startDateMenu" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto">
                  <template #activator="{ on, attrs }">
                    <v-text-field outlined dense hide-details prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                      v-model="leaveObj.startDate" label="Start date" @focus="leaveObj.start = $_formatDate(leaveObj.startDate, 'DD.MM.YYYY', 'YYYY-MM-DD')">
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="leaveObj.start" @change="validateStartAndEndDate({ start: true })"
                  @input="leaveObj.startDate = $_formatDate(leaveObj.start, 'YYYY-MM-DD', 'DD.MM.YYYY'); leaveObj.startDateMenu = false;"></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex lg6 class="pl-1">
                <v-select outlined dense hide-details :items="selectHalfItems" v-model="leaveObj.startHalfSelectItem"
                label="Select half" :disabled="leaveObj.startHalfDisable" @change="validateLeavePayload"></v-select>
              </v-flex>
              <v-flex lg6 class="pr-1 pt-2">
                <v-menu v-model="leaveObj.endDateMenu" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto">
                  <template #activator="{ on, attrs }">
                    <v-text-field outlined dense hide-details prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                      v-model="leaveObj.endDate" label="End date" @focus="leaveObj.end = $_formatDate(leaveObj.endDate, 'DD.MM.YYYY', 'YYYY-MM-DD')">
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="leaveObj.end" @change="validateStartAndEndDate({ start: false })"
                    @input="leaveObj.endDate = $_formatDate(leaveObj.end, 'YYYY-MM-DD', 'DD.MM.YYYY'); leaveObj.endDateMenu = false;">
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex lg6 class="pl-1 pt-2">
                <v-select outlined dense hide-details :items="selectHalfItems" v-model="leaveObj.endHalfSelectItem"
                label="Select half" :disabled="leaveObj.endHalfDisable" @change="validateLeavePayload"></v-select>
              </v-flex>
              <v-flex lg12 class="pt-2">
                <v-textarea outlined dense hide-details hide-spin-buttons auto-grow rows="2" label="Reason" autocomplete="off"
                v-model="leaveObj.reason" :rules="setValidation('requiredValidation')" validate-on-blur></v-textarea>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="applyLeave"> Apply </v-btn>
          <v-btn color="error" @click="applyLeaveDialog = false"> Cancel </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmLeaveUpdateDialog" persistent width="400">
      <v-card>
        <v-card-title :class="['pa-3 pb-2 pl-3 white--text darken-1', updateLeaveDialogObj.status === 2 ? 'primary' : 'error']">
          <span class="body-1 font-weight-medium"> Confirmation! </span>
        </v-card-title>
        <v-card-text class="pa-5">
          <span>
            Are you sure to
            <span class="font-weight-bold">
              {{ `${updateLeaveDialogObj.status === 2 ? 'approve' : updateLeaveDialogObj.status === 3 ? 'cancel' : 'reject'}` }}
            </span> ?
          </span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small :color="updateLeaveDialogObj.status === 2 ? 'primary' : 'error'" @click="updateLeaveRecord"> Yes </v-btn>
          <v-btn small :color="updateLeaveDialogObj.status === 2 ? 'error' : 'primary'" @click="confirmLeaveUpdateDialog = false"> No </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="viewLeaveItemDialog" persistent width="550">
      <v-card>
        <v-card-title class="primary darken-2 white--text pl-5 pr-3 py-2">
          <span class="body-2 font-weight-medium"> Leave details </span>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="viewLeaveItemDialog = false;">
            <v-icon small> mdi-close-circle </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-card tile flat max-height="77vh" class="overflow-y-auto py-4">
            <!-- {{ selectedLeaveObj.actionby }} -->
            <table class="px-5 leavedialogtable">
              <tr>
                <td> From </td>
                <td>
                  <span> {{ $_formatDate(selectedLeaveObj.start, 'YYYY-MM-DDTHH:mm:ss', 'DD.MM.YYYY') }} </span>
                  <span class="caption"> ({{ selectedLeaveObj.startHalfSelectItem === 1 ? 'Morning' : 'Afternoon' }}) </span>
                </td>
              </tr>
              <tr>
                <td> Until </td>
                <td>
                  <span> {{ $_formatDate(selectedLeaveObj.end, 'YYYY-MM-DDTHH:mm:ss', 'DD.MM.YYYY') }} </span>
                  <span class="caption"> ({{ selectedLeaveObj.endHalfSelectItem === 1 ? 'Morning' : 'Afternoon' }}) </span>
                </td>
              </tr>
              <tr>
                <td> Total leave days </td>
                <td> {{ selectedLeaveObj.totalLeaveCount }} day(s) </td>
              </tr>
              <tr>
                <td> Status </td>
                <td> {{ getLeaveTypeStatus(selectedLeaveObj.status).text }} </td>
              </tr>
              <tr v-if="(selectedLeaveObj.status !== 1 && selectedLeaveObj.actionby)">
                <td> Approved/ Rejected by </td>
                <td> {{ selectedLeaveObj.actionby.formdata.name }} </td>
              </tr>
              <tr v-if="selectedLeaveObj.leavetype">
                <td> Leavetype </td>
                <td> {{ selectedLeaveObj.leavetype ? selectedLeaveObj.leavetype.name : '' }} </td>
              </tr>
              <tr>
                <td> Reason </td>
                <td> {{ selectedLeaveObj.reason }} </td>
              </tr>
              <tr>
                <td> Applied at </td>
                <td> {{ $_formatDate(selectedLeaveObj.createdAt, 'YYYY-MM-DDTHH:mm:ss', 'DD.MM.YYYY HH:mm') }} </td>
              </tr>
            </table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      managerMode: false,
      applyLeaveDialog: false,
      confirmLeaveUpdateDialog: false,
      leaveObj: {},
      classItems: [],
      studentItems: [],
      staffItems: [],
      updateLeaveDialogObj: {},
      leaveTypes: [],
      selectHalfItems: [{
        text: 'First half',
        value: 1
      }, {
        text: 'Second half',
        value: 2
      }],
      leaveStatusTypes: [{
        text: 'Pending',
        value: 1,
        color: 'orange'
      }, {
        text: 'Approved',
        value: 2,
        color: 'success'
      }, {
        text: 'Cancelled',
        value: 3,
        color: 'purple'
      }, {
        text: 'Rejected',
        value: 4,
        color: 'error'
      }],
      payload: {
        items: [],
        create: {
          text: 'Apply',
          color: 'success darken-1',
          click: () => this.applyLeaveDialogHandler()
        },
        // defaultView: 'card',
        views: [{
          name: 'list',
          show: true
        }],
        // config: () => this.$router.push('/leave/manage'),
        filter: 'leave-payloadFilter',
        // config: [{
        //   title: 'Export',
        //   icon: 'mdi-application-export',
        //   iconSize: 17,
        //   click: () => {},
        //   show: true
        // }, {
        //   title: 'Import',
        //   icon: 'mdi-application-import',
        //   iconSize: 17,
        //   click: () => {},
        //   show: true
        // }, {
        //   title: 'Manage',
        //   icon: 'mdi-cog',
        //   click: () => this.$router.push('/leave/manage'),
        //   show: true
        // }],
        actionsList: [{
          title: 'View',
          icon: 'mdi-eye',
          iconSize: 19,
          color: 'primary',
          click: this.openLeaveDialogHandler
        }, {
          title: 'Cancel',
          icon: 'mdi-cancel',
          color: 'error',
          show: ({ status }) => ([1, 2].includes(status)),
          click: (item) => this.updateLeaveRecord(item, 3)
        }]
      },
      leaveRequestPayload: {
        items: [],
        views: [{
          name: 'list',
          show: true
        }],
        filter: 'leave-leaveRequestFilter',
        // config: [{
        //   title: 'Export',
        //   icon: 'mdi-application-export',
        //   iconSize: 17,
        //   click: () => {},
        //   show: true
        // }, {
        //   title: 'Import',
        //   icon: 'mdi-application-import',
        //   iconSize: 17,
        //   click: () => {},
        //   show: true
        // }, {
        //   title: 'Manage',
        //   icon: 'mdi-cog',
        //   click: () => this.$router.push('/leave/manage'),
        //   show: true
        // }],
        config: () => this.$router.push('/leave/manage'),
        actionsList: [{
          title: 'View',
          icon: 'mdi-eye',
          iconSize: 19,
          color: 'primary',
          click: this.openLeaveDialogHandler
        }, {
          title: 'Approve',
          icon: 'mdi-checkbox-marked-circle-outline',
          color: 'success',
          // show: ({ status }) => (status !== 2),
          show: ({ status }) => ([1, 4].includes(status)),
          click: (item) => this.updateLeaveRecord(item, 2)
        }, {
          title: 'Reject',
          icon: 'mdi-cancel',
          color: 'error',
          show: ({ status }) => ([1, 2].includes(status)),
          click: (item) => this.updateLeaveRecord(item, 4)
        }]
      },
      viewMode: 3, // 2-staff, 3-student
      viewLeaveItemDialog: false,
      selectedLeaveObj: {}
    }
  },
  components: {
    'data-iterator': () => import('@/components/DataIterator.vue')
  },
  beforeMount () {
    this.getLeaveTypes()
  },
  mounted () {
    this.getAllLeaveRecords()
    this.getAllLeaveRequests({ role: 3 })
    this.getAllClassRecords()
    this.getAllUserRecords(3, 'studentItems')
    this.getAllUserRecords(2, 'staffItems')
  },
  computed: {
    payloadHeaders () {
      return [{
        text: 'Start',
        value: 'start',
        type: 'DATE',
        sortable: false
      }, {
        text: 'End',
        value: 'end',
        type: 'DATE',
        sortable: false
      }, ((this.userDetails.role && this.userDetails.role.code === 2) && {
        text: 'Leave type',
        value: 'leavetype',
        type: 'SELECT',
        items: this.leaveTypes,
        condition: ({ header, item }) => item[header.value].name
      }), {
        text: 'Reason',
        value: 'reason',
      }, {
        text: 'Status',
        value: 'status',
        type: 'SELECT',
        items: this.leaveStatusTypes,
        condition: ({ header, item }) => this.leaveStatusTypes.find((x) => x.value === item[header.value]).text,
      }, {
        text: 'Actions',
        value: 'actions',
        sortable: false
      }].filter(x => x)
    },
    leaveRequestHeaders () {
      return [(this.viewMode === 2 && {
        text: 'Staff',
        value: 'author',
        slot: 'staff',
        type: 'AUTOCOMPLETE',
        items: this.staffItems,
        dbRef: 'author._id',
        item_text: 'formdata.name',
        item_value: '_id'
      }), (this.viewMode === 3 && {
        text: 'Student',
        value: 'author',
        dbRef: 'author._id',
        type: 'AUTOCOMPLETE',
        items: this.studentItems,
        item_text: 'formdata.name',
        item_value: '_id',
        slot: 'student'
      }), {
        text: 'Start',
        value: 'start',
        type: 'DATE',
        sortable: false
      }, {
        text: 'End',
        value: 'end',
        type: 'DATE',
        sortable: false
      }, ...(this.viewMode === 2 ? [{
        text: 'Leave type',
        value: 'leavetype',
        type: 'SELECT',
        items: this.leaveTypes,
        condition: ({ header, item }) => {
          // console.log('in', header, item, item[header.value])
          return item[header.value] ? item[header.value].name : ''
        }
      }] : []), {
        text: 'Reason',
        value: 'reason'
      }, {
        text: 'Status',
        value: 'status',
        type: 'SELECT',
        items: this.leaveStatusTypes,
        condition: ({ header, item }) => this.leaveStatusTypes.find((x) => x.value === item[header.value]).text
      }, {
        text: 'Actions',
        value: 'actions',
        sortable: false
      }].filter(x => x)
    }
  },
  methods: {
    getLeaveTypes () {
      const query = [{
        $set: { _id: { $toString: '$_id' } }
      }, {
        $match: { _id: this.userDetails._id }
      }, {
        $unwind: { path: "$leavebalance", preserveNullAndEmptyArrays: true }
      }, {
        $lookup: {
          from: "LeaveTypes",
          localField: "leavebalance.leavetypeId",
          foreignField: "_id",
          as: "leaveTypeObj"
        }
      }, {
        $unwind: { path: "$leaveTypeObj", preserveNullAndEmptyArrays: true }
      }, {
        $set: { "leaveTypeObj.balance" : "$leavebalance.balance" }
      }, {
        $group: {
          _id: "$_id",
          leavebalance: { $push: "$leaveTypeObj" }
        }
      }]
      this.$_execute('post', 'users/query', query)
        .then(({ data } = {}) => {
          if (!data.length) {
            this.leaveTypes = []
            return
          }
          const [user] = data
          this.leaveTypes = user.leavebalance.filter((leavetype) => (leavetype.balance))
        })
    },
    getAllClassRecords () {
      let query = [{
          $set: {
            fullname: {
              $concat: ["$name", " - ", "$section"]
            }
          }
        }]
        this.$_execute('post', 'classes/query', query)
          .then(({ data }) => {
            this.classItems = data
          })
    },
    getAllUserRecords (role, payload) {
      let query = [{
        $lookup: {
          from: 'UserRoles',
          foreignField: '_id',
          localField: 'role',
          as: 'role',
        }
      }, {
        $unwind: { path: '$role', preserveNullAndEmptyArrays: true }
      }, {
        $match: {
          'role.code': role,
        }
      }]
      this.$_execute('post', 'users/query', query)
        .then(({ data }) => {
          if (data) this[payload] = data
        })
    },
    getAllLeaveRecords ({ queryObj } = {}) {
      const query = [{
        $set: {
          author: { $toString: '$author' }
        }
      }, {
        $match: {
          author: this.userDetails._id
        }
      }, {
        $lookup: {
          from: 'Users',
          foreignField: '_id',
          localField: 'actionby',
          as: 'actionby'
        }
      }, {
        $unwind: {
          path: '$actionby',
          preserveNullAndEmptyArrays: true
        }
      }, {
        // in future, we can remove the lookup, for student type
        $lookup: {
          from: 'LeaveTypes',
          foreignField: '_id',
          localField: 'leavetype',
          as: 'leavetype'
        }
      }, {
        $unwind: {
          path: '$leavetype',
          preserveNullAndEmptyArrays: true
        }
      }]
      if (queryObj) query.push(queryObj)
      this.$_execute('post', 'leave/query', query).then(({ data }) => {
        this.payload.items = data
      })
    },
    getAllLeaveRequests ({ queryObj, role } = {}) { // eslint-disable-line
      let query = [{
        $lookup: {
          from: 'Users',
          foreignField: '_id',
          localField: 'author',
          as: 'author',
        }
      }, {
        $unwind: {
          path: '$author',
          preserveNullAndEmptyArrays: true
        }
      }, {
        $set: {
          'author._id': {
            $convert: {
              input: '$author._id',
              to: 'string'
            }
          }
        }
      }, {
        $lookup: {
          from: 'UserRoles',
          foreignField: '_id',
          localField: 'author.role',
          as: 'author.role'
        }
      }, {
        $unwind: {
          path: '$author.role',
          preserveNullAndEmptyArrays: true
        }
      }, {
        $lookup: {
          from: 'Users',
          foreignField: '_id',
          localField: 'actionby',
          as: 'actionby'
        }
      }, {
        $unwind: {
          path: '$actionby',
          preserveNullAndEmptyArrays: true
        }
      }, {
        $match: {
          'author.role.code': role || this.viewMode,
          ...((this.userDetails.role.code === 2) && {
            'author.formdata.staff_incharge': {
              $in: [this.userDetails._id]
            }
          })
        }
      }, {
        // in future, we can remove the lookup, for student type
        $lookup: {
          from: 'LeaveTypes',
          foreignField: '_id',
          localField: 'leavetype',
          as: 'leavetype'
        }
      }, {
        $unwind: {
          path: '$leavetype',
          preserveNullAndEmptyArrays: true
        }
      }]
      if (queryObj) query.push(queryObj)
      this.$_execute('post', 'leave/query', query)
        .then(({ data }) => {
          this.leaveRequestPayload.items = [...data]
        })
    },
    applyLeaveDialogHandler () {
      this.leaveObj = {
        startDate: this.$moment().add(1, 'd').format('DD.MM.YYYY'),
        startHalfSelectItem: 1,
        endDate: this.$moment().add(1, 'd').format('DD.MM.YYYY'),
        endHalfSelectItem: 2,
        start: this.$moment().add(1, 'd').format('YYYY-MM-DD'),
        end: this.$moment().add(1, 'd').format('YYYY-MM-DD'),
        // leavetype: this.userDetails.role.code === 3 ? 3 : 21,
        leavetype: (this.leaveTypes.length && this.userDetails.role.code !== 3) ? this.leaveTypes[0]._id : null,
        class: this.userDetails.formdata.classroom
      }
      this.applyLeaveDialog = true
    },
    async applyLeave () {
      if (this.$refs.leaveForm && this.$refs.leaveForm.validate()) {
        const leaveObj = this.$_copy(this.leaveObj)
        let payload = {
          ...leaveObj,
          start: this.$moment(leaveObj.start, 'YYYY-MM-DD').format(`YYYY-MM-DDT${leaveObj.startHalfSelectItem === 1 ? '09:00' : '13:00'}`),
          end: this.$moment(leaveObj.end, 'YYYY-MM-DD').format(`YYYY-MM-DDT${leaveObj.endHalfSelectItem === 1 ? '13:00' : '17:00'}`),
          author: this.userDetails._id,
          class: this.userDetails.formdata.classroom,
          totalLeaveCount: await this.calculateTotalLeaveCount({ payload: leaveObj })
        }
        payload = this.$_formatModelToUtcDate({
          model: payload,
          keys: ['start', 'end'],
          from: 'YYYY-MM-DDTHH:mm',
          to: 'YYYY-MM-DDTHH:mm'
        })
        this.$_execute('post', 'leave', payload)
          .then(() => {
            this.getAllLeaveRecords()
            this.applyLeaveDialog = false
          })
      } else {
        this.$root.$emit('snackbar', { color: 'error', message: 'Please check the required fields' })
      }
    },
    validateLeavePayload () {
      const diff = this.$moment(this.leaveObj.end, 'YYYY-MM-DD').diff(this.leaveObj.start, 'd')
      if (diff === 0) {
        if (this.leaveObj.startHalfSelectItem === 2) {
          this.leaveObj = {
            ...this.leaveObj,
            endHalfSelectItem: 2,
            endHalfDisable: true,
            startHalfDisable: false
          }
        } else if (this.leaveObj.endHalfSelectItem === 1) {
          this.leaveObj = {
            ...this.leaveObj,
            startHalfSelectItem: 1,
            startHalfDisable: true,
            endHalfDisable: false
          }
        } else {
          this.leaveObj = {
            ...this.leaveObj,
            startHalfDisable: false,
            endHalfDisable: false
          }
        }
      } else {
        this.leaveObj = {
          ...this.leaveObj,
          startHalfDisable: false,
          endHalfDisable: false
        }
      }
    },
    async calculateTotalLeaveCount ({ payload }) {
      const { start, end, startHalfSelectItem: firstHalf, endHalfSelectItem: lastHalf } = payload
      const diff = this.$moment(end, 'YYYY-MM-DD').diff(start, 'd')
      return (firstHalf === 2 && lastHalf === 1) ? diff : (firstHalf === 1) ? ((lastHalf === 1) ? 0.5 : 1) + diff : ((lastHalf === 2) ? 0.5 : 1) + diff
    },
    updateLeaveRecord (item, status) {
      if (item && item._id) {
        this.updateLeaveDialogObj = {
          ...item,
          status,
          actionby: this.userDetails._id
        }
        this.confirmLeaveUpdateDialog = true
        return
      }
      this.$_execute('put', `leave/${this.updateLeaveDialogObj._id}`, this.updateLeaveDialogObj)
        .then(() => {
          if (this.updateLeaveDialogObj.status === 3) this.getAllLeaveRecords()
          else this.getAllLeaveRequests({ role: this.viewMode })
        })
        .finally(() => {
          this.confirmLeaveUpdateDialog = false
        })
    },
    validateStartAndEndDate ({ start = false }) {
      const diff = this.$moment(this.leaveObj.end, 'YYYY-MM-DD').diff(this.$moment(this.leaveObj.start, 'YYYY-MM-DD'), 'd')
      if (diff < 0) this.leaveObj = Object.assign(this.leaveObj, { [start ? 'end' : 'start']: null, [start ? 'endDate' : 'startDate']: null })
      this.validateLeavePayload()
    },
    openLeaveDialogHandler (item) {
      if (!this.managerMode) item.author = this.userDetails
      this.selectedLeaveObj = item
      this.viewLeaveItemDialog = true
    },
    getLeaveTypeStatus (code) {
      const status = this.leaveStatusTypes.find((item) => item.value === code)
      return status || {}
    }
  }
}
</script>
<style>
  .leavedialogtable tr td:nth-child(1) {
    font-size: 0.85rem;
  }
  .leavedialogtable tr td:nth-child(2) {
    font-size: 0.9rem;
    color: rgb(104, 104, 104);
    padding: 0 40px !important;
  }
</style>
